import { ProductNameToIdMap } from "src/constants/ProductIds";
import { Company, FolderNode, Product, ReturnNode } from "src/types";

/**
 * Generates a unique key based on return node, product, and company.
 * @param {Object} returnNode - The return node object.
 * @param {Object} product - The product object.
 * @param {Object} company - The company object.
 * @return {string} A formatted unique key.
 */
export function generateUniqueKey(
    folderNodeId: string,
    returnNode: ReturnNode,
    product: Product,
    companyName: string,
    state: string | null = null
    ): string {
    // Generate unique key logic here
    let key = `${folderNodeId}-${returnNode?.id}-${returnNode?.displayName}-${product?.productName}-${product?.taxYear}-${companyName}`;

    if (product.productId === ProductNameToIdMap.get("Municipal")) {
        // Append state and quarter if they are provided
        if (state !== null) {
            key += `-${state}`;
        }
    }
    return key;
}
