import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSearchParams } from "react-router-dom";
import { closeSnackbar, SnackbarKey, useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorService, WebSocketService } from "src/services";
import colors from "src/styles/colors.scss";
import {
    ALLOCATOR_SEARCH_PARAMS,
    AllocatorJob,
    AllocatorJobsPayload,
    AllocatorReportsPageSize,
    FixMeLater,
    JOB_STATUS_TYPES,
    NotificationEvent,
    SSE_TYPES,
} from "src/types";
import Loader from "src/components/Loader/Loader";
import "./AllocatorLanding.scss";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import JobListGrid from "../JobListGrid/JobListGrid";
import JobSummaryGrid from "../JobSummaryGrid/JobSummaryGrid";
import ReportDataGrid from "../ReportDataGrid/ReportDataGrid";
import ReportTabs from "../ReportTabs/ReportTabs";
import { DEFAULT_SEARCH_PARAMS } from "../../constant";
import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { getFirstPathSegment } from "src/services/Utility";
import BulkExport from "../JobListGrid/components/BulkExport/BulkExport";
import CompanyDropdown from "../CompanyDropdown/CompanyDropdown";
import { PRODUCT_NAME } from "src/constants";

const PAGE_SIZE = 10;

const AllocatorLanding: FC = () => {
    const allocatorService = AllocatorService.getInstance();
    const webSocketService = WebSocketService.getInstance();

    const [totalPages, setTotalPages] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userCompanies, setUserCompanies] = useState<string[]>([]);
    const [lastSelectedCompany, setLastSelectedCompany] = useState<string>();
    const [searchParams, setSearchParams] = useSearchParams();
    const { getRootProps, isDragActive } = useDropzone({
        noClick: true,
    });
    const wsRef = useRef<WebSocket | null>(null);
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const jobList: AllocatorJob[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.jobList
    );
    const selectedJob: AllocatorJob = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.selectedJob
    );
    const page: number = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.page
    );
    const toogleFetchJobs: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.toogleFetchJobs
    );
    const filterState: AllocatorJobsPayload = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.filterState
    );
    const reportsPageSize: AllocatorReportsPageSize = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportsPageSize
    );
    const selectedCompany: string = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company,
    );

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const reportId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.REPORT);

    const acceptStyle = {
        height: jobList?.length || Number(jobId) || lastSelectedCompany ? "80vh" : "",
        border: `1px dashed ${colors.allocatorSelectedContainerBorderColor}`,
        backgroundColor: colors.allocatorComponentBackgroundColor,
    };

    const style = useMemo(
        () => ({
            ...(isDragActive ? acceptStyle : {}),
        }),
        [isDragActive]
    );

    const dragAndDropConfig = {
        isEmptyPage: !jobList?.length && !Number(jobId) && !lastSelectedCompany,
        style,
    };

    const handleMessage = ({ eventType, payload }: NotificationEvent) => {
        switch (eventType) {
            case SSE_TYPES.JOB_PROGRESS:
            case SSE_TYPES.JOB_STATUS_UPDATED:
                dispatch(
                    GlobalStateActions[product?.productName].setUpdatedJob(
                        payload
                    )
                );
                break;
            case SSE_TYPES.MESSAGE:
                if (payload?.errorId) {
                    const action = (snackbarId: SnackbarKey) => (
                        <>
                            <Button color="inherit" size="small" onClick={() => handleNavigate(payload?.errorId)}>
                                    View Warning Log
                            </Button>
                            <IconButton
                                color="inherit"
                                sx={{ p: 0.5, marginLeft: 1 }}
                                onClick={() => { closeSnackbar(snackbarId) }}
                            >
                                <Close />
                            </IconButton>
                        </>
                    );

                    enqueueSnackbar(payload?.message, { variant: "error", action });
                } else {
                    enqueueSnackbar(payload?.message, { variant: "error" });
                }
                break;
            default:
                break;
        }
    };

    const handleNavigate = (jobId: number | undefined) => {
        window.open(
            `${
                window.location.origin
            }/${getFirstPathSegment()}/warnings/${jobId}`,
            "_blank"
        );
    };

    useEffect(() => {
        const fetchNotifications = async () => {
            if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
                try {
                    const socket = await webSocketService.openWebSocketConnection(product.productName?.toLowerCase());

                    socket.onopen = () => {
                        socket.send("opened");

                        const keepAliveIntervalId = setInterval(
                            () => {
                                if (socket && wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                                    socket.send("keepalive");
                                } else {
                                    clearInterval(keepAliveIntervalId);
                                }
                            },
                            // Set the interval to 30 seconds to prevent from becoming inactive.
                            30 * 1000 
                        );
                    };

                    socket.onmessage = (event: MessageEvent) => {
                        handleMessage(JSON.parse(event?.data));
                    };

                    socket.onerror = (event) => {
                        console.error("Error fetching notifications", event);
                        enqueueSnackbar("Error fetching notifications", { variant: "error" });
                    };

                    socket.onclose = (event: CloseEvent) => {
                        if (!event.wasClean) {
                            console.error(
                                "Socket is closed. Reconnect will be attempted in 10 seconds.",
                                event
                            );
                            setTimeout(() => {
                                fetchNotifications();
                            }, 10000);
                        }
                    };

                    wsRef.current = socket;

                } catch (error) {
                    enqueueSnackbar("Error fetching notifications", { variant: "error" });
                }
            }
        };

        fetchNotifications();

        return () => {
            // Anything in here is fired on component unmount.
            dispatch(GlobalStateActions[product?.productName].setJobsPage(0));
            dispatch(GlobalStateActions[product?.productName].setJobList([]));
            dispatch(
                GlobalStateActions[product?.productName].setSelectedJob(null)
            );
            dispatch(
                GlobalStateActions[product?.productName].setJobsFilterState(null)
            );
            dispatch(GlobalStateActions[product?.productName].setEmptyReportsFilterState());

            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if (page) fetchJobList();
    }, [page]);

    useEffect(() => {
        if (filterState !== undefined) fetchJobList();
    }, [filterState, selectedCompany]);

    useEffect(() => {
        setIsLoading(true);
    }, [toogleFetchJobs]);

    useEffect(() => {
        if (Number(jobId) !== selectedJob?.id) {
            dispatch(GlobalStateActions[product?.productName].setEmptyReportsFilterState());
            dispatch(
                GlobalStateActions[product?.productName].setSelectedJob(
                    jobList?.find(
                        (job: AllocatorJob) => job?.id === Number(jobId)
                    )
                )
            );
        }
    }, [jobId]);

    const fetchJobList = async () => {
        try {
            const jobsPayload = {...filterState};

            if (selectedCompany) {
                jobsPayload.lastSelectedCompanyAbbr = selectedCompany;
            }

            const jobsResponse = await allocatorService.generateJobsList(
                page,
                PAGE_SIZE,
                jobsPayload
            );
            const newJobList = jobsResponse?.page?.content;
            setTotalPages(jobsResponse?.page?.totalPages - 1);
            setUserCompanies(jobsResponse?.meta?.userCompanies);
            setLastSelectedCompany(jobsResponse?.meta?.lastSelectedCompanyAbbr);

            const jobListCombined = page
                ? [...jobList, ...newJobList]
                : newJobList;
            dispatch(
                GlobalStateActions[product?.productName].setJobList(
                    jobListCombined
                )
            );
            if (!Number(jobId)) {
                const isJobCompleted = jobListCombined[0]?.status?.name !== JOB_STATUS_TYPES.FAILED 
                    && jobListCombined[0]?.status?.name !== JOB_STATUS_TYPES.PROCESSING;

                setSearchParams(
                    `?${new URLSearchParams({
                        jobId: jobListCombined[0]?.id?.toString() ?? "0",
                        report: isJobCompleted ? DEFAULT_SEARCH_PARAMS.summary : DEFAULT_SEARCH_PARAMS.report,
                        page: DEFAULT_SEARCH_PARAMS.page,
                        size: reportsPageSize[jobListCombined[0]?.id?.toString()] ?? DEFAULT_SEARCH_PARAMS.size,
                    })}`
                );

                if (!selectedJob) {
                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedJob(jobListCombined[0])
                    );
                }
            } else {
                const jobById = jobListCombined.find(
                    (job) => job?.id === Number(jobId)
                );

                if (!selectedJob && jobById) {
                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setSelectedJob(jobById)
                    );
                }
            }
        } catch (error) {
            enqueueSnackbar("Error fetching Allocator jobs", { variant: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    if ((isLoading && !jobList.length) || !jobId || product?.productName !== PRODUCT_NAME.ALLOCATOR) {
        return <Loader />;
    }

    return (
        <div {...getRootProps({ className: "allocator-container" })}>
            {!jobList?.length && !Number(jobId) && !lastSelectedCompany ? (
                <div className="allocator-container-landing">
                    <h1>Upload a file to create the first job</h1>
                    <div className="allocator-container-landing-upload">
                        <DragAndDrop 
                            dragAndDropConfig={dragAndDropConfig} 
                            lastSelectedCompany={lastSelectedCompany} 
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="allocator-left-container">
                        <div className="allocator-left-container-upload">
                            <DragAndDrop
                                dragAndDropConfig={dragAndDropConfig}
                                lastSelectedCompany={lastSelectedCompany}
                            />
                        </div>
                        {isLoading && !page ? (
                            <Loader />
                        ) : (
                            <>
                                <CompanyDropdown 
                                    userCompanies={userCompanies} 
                                    lastSelectedCompany={lastSelectedCompany} 
                                    isDragActive={isDragActive}
                                />
                                <JobListGrid
                                    jobList={jobList}
                                    page={page}
                                    totalPages={totalPages}
                                    isLoading={isLoading}
                                    isDragActive={isDragActive}
                                />
                                <BulkExport isDragActive={isDragActive} />
                            </>
                        )}
                    </div>
                    {!Number(jobId) ? (
                        <div className="allocator-container-landing">
                            <h1>Upload a file to create the first job</h1>
                        </div>
                    ) : (
                    <div className="allocator-right-container">
                        <ReportTabs />
                        <div className="reports-container">
                            {reportId === DEFAULT_SEARCH_PARAMS.summary ? (
                                <JobSummaryGrid key={String(jobId)}/>
                            ) : (
                                <ReportDataGrid
                                    key={String(jobId) + String(reportId)}
                                />
                            )}
                        </div>
                    </div>
                    )}
                </>
            )}
        </div>
    );
};

export default AllocatorLanding;
