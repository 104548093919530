import { useAuth0 } from "@auth0/auth0-react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { ReturnDocumentService } from "src/services";
import { returnTreeQuery } from "src/services/GQLQueries";
import GQLService from "src/services/GQLService";
import { getModuleId } from "src/services/Utility";
import { FixMeLater, FolderNode, QueryParams, ReturnNode } from "src/types";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import "./FolderNodeDialog.scss";
import { StyledFlexContainer } from "./FolderNodeDialog.styled";
import { filterForReturnNodes } from "src/utils/TreeUtils";
import { getQueryParams } from "src/utils";
import { Modal } from "src/uikit";

interface FolderNodeDialogProps {
    folderNode: FolderNode;
    open: boolean;
    onClose: () => void;
}

const FolderNodeDialog: React.FC<FolderNodeDialogProps> = ({
    folderNode,
    open,
    onClose,
}) => {
    const returnDocumentService = ReturnDocumentService.getInstance();
    const dispatch = useAppDispatch();

    const { getAccessTokenSilently } = useAuth0();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>(
        "Returns updated successfully!"
    );
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const company = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company
    );

    const municipalState: String = useAppSelector(
        (state) => state?.Municipal?.value.selectedState
    );

    const returnNodeMap = folderNode?.returnNodes?.reduce((map, returnNode) => {
        map[returnNode.id] = returnNode;
        return map;
    }, {});

    // This set will be used to track the state of the checkboxes.
    const [activatedReturnNodes, setActivatedReturnNodes] = useState(new Set());
    const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
        useState<boolean>(false);

    useEffect(() => {
        setActivatedReturnNodes(
            new Set(
                folderNode?.returnNodes
                    ?.filter((returnNode) => returnNode?.isActive)
                    ?.map((returnNode) => returnNode?.id)
            )
        );
    }, [folderNode]);

    // These are the change sets that will be sent to the API.
    const [activateReturnNodes, setActivateReturnNodes] = useState<Set<string>>(
        new Set()
    );
    const [deactivateReturnNodes, setDeactivateReturnNodes] = useState<
        Set<string>
    >(new Set());

    const handleActivateChange = (returnNodeId: string) => {
        setActivatedReturnNodes((prevActivatedNodes) => {
            const newActivatedNodes = new Set(prevActivatedNodes);

            // Uncheck the checkbox for activation
            if (newActivatedNodes.has(returnNodeId)) {
                newActivatedNodes.delete(returnNodeId);

                // If the return node is active already, we need to add it to the deactivate set.
                if (returnNodeMap[returnNodeId].isActive) {
                    setDeactivateReturnNodes((prevDeactivatedNodes) => {
                        const newDeactivatedNodes = new Set(
                            prevDeactivatedNodes
                        );
                        newDeactivatedNodes.add(returnNodeId);
                        return newDeactivatedNodes;
                    });
                }

                if (activateReturnNodes.has(returnNodeId)) {
                    setActivateReturnNodes((prevActivateNodes) => {
                        const newActivateNodes = new Set(prevActivateNodes);
                        newActivateNodes.delete(returnNodeId);
                        return newActivateNodes;
                    });
                }
            }
            // check the checkbox for activation
            else {
                newActivatedNodes.add(returnNodeId);

                // If the return node is inactive already, we need to add it to the activate set.
                if (!returnNodeMap[returnNodeId].isActive) {
                    setActivateReturnNodes((prevActivateNodes) => {
                        const newActivateNodes = new Set(prevActivateNodes);
                        newActivateNodes.add(returnNodeId);
                        return newActivateNodes;
                    });
                }

                if (deactivateReturnNodes.has(returnNodeId)) {
                    setDeactivateReturnNodes((prevDeactivatedNodes) => {
                        const newDeactivatedNodes = new Set(
                            prevDeactivatedNodes
                        );
                        newDeactivatedNodes.delete(returnNodeId);
                        return newDeactivatedNodes;
                    });
                }
            }

            return newActivatedNodes;
        });
    };

    const handleSave = async () => {
        if (deactivateReturnNodes.size > 0) {
            setShowDeactivateConfirmation(true);
            return;
        }

        await saveData();
    };

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const saveData = async () => {
        try {
            setIsLoading(true);
            const accessToken = await getAccessTokenSilently();

            const commonParams: QueryParams = getQueryParams({
                companyId: company?.id,
                productId: `${ProductNameToIdMap.get(product?.productName)}`,
                taxYearId: product?.taxYear,
                folderId: folderNode?.id,
                moduleId: getModuleId(product, company, municipalState),
            });

            const createPayload = (returnNodes: Set<string>) =>
                Array.from(returnNodes).map((returnNodeId) => {
                    const params: QueryParams = getQueryParams({
                        ...commonParams,
                        returnId: returnNodeId,
                        retalFolderId:
                            returnNodeMap[returnNodeId].retalFolderId,
                    });
                    return params;
                });

            // Check if there are any returns that require retal activation.
            const needToActivateRetalReturn =
                Array.from(folderNode?.returnNodes).some(
                    (returnNode: ReturnNode) =>
                        activateReturnNodes.has(returnNode?.id as string) &&
                        returnNode?.retalFolderId == folderNode?.id &&
                        returnNode?.activateRetal
                ) &&
                Array.from(folderNode?.returnNodes).some(
                    (returnNode: ReturnNode) =>
                        !returnNode?.isActive &&
                        returnNode?.retalFolderId != folderNode?.id
                );

            let retalReturnsToBeActivated: String[] = [];

            if (needToActivateRetalReturn) {
                retalReturnsToBeActivated = Array.from(folderNode?.returnNodes)
                    .filter(
                        (returnNode: ReturnNode) =>
                            !returnNode?.isActive &&
                            returnNode?.retalFolderId != folderNode?.id
                    )
                    .map((returnNode: ReturnNode) => returnNode?.id as string);
            }

            // Check if there are any returns that require retal deactivation.
            const needToDeactivateRetalReturn =
                !needToActivateRetalReturn && // There should not be any returns that needs retal activation to be able to deactivate retals.
                Array.from(folderNode?.returnNodes).some(
                    (returnNode: ReturnNode) =>
                        deactivateReturnNodes.has(returnNode?.id as string) &&
                        returnNode?.retalFolderId == folderNode?.id &&
                        returnNode?.activateRetal
                ) &&
                Array.from(folderNode?.returnNodes).some(
                    (returnNode: ReturnNode) =>
                        returnNode?.isActive &&
                        returnNode?.retalFolderId != folderNode?.id
                );

            let retalReturnsToBeDeactivated: String[] = [];

            if (needToDeactivateRetalReturn) {
                retalReturnsToBeDeactivated = Array.from(
                    folderNode?.returnNodes
                )
                    .filter(
                        (returnNode: ReturnNode) =>
                            returnNode?.isActive &&
                            returnNode?.retalFolderId != folderNode?.id
                    )
                    .map((returnNode: ReturnNode) => returnNode?.id as string);
            }

            const toBeActivated = new Set(activateReturnNodes);
            const toBeDeactivated = new Set(deactivateReturnNodes);

            retalReturnsToBeActivated.forEach((returnId) => {
                toBeActivated.add(returnId as string);
            });

            retalReturnsToBeDeactivated.forEach((returnId) => {
                toBeDeactivated.add(returnId as string);
            });

            const activationPayloads = createPayload(toBeActivated);
            const deactivationPayloads = createPayload(toBeDeactivated);

            let activationSuccess = true;
            let deactivationSuccess = true;

            if (activateReturnNodes.size !== 0) {
                try {
                    await returnDocumentService.activateReturnNodes(
                        activationPayloads
                    );
                    dispatch(GlobalStateActions.setToogleFetch());
                } catch (error) {
                    activationSuccess = false;
                }
            }

            if (deactivateReturnNodes.size !== 0) {
                try {
                    await returnDocumentService.deactivateReturnNodes(
                        deactivationPayloads
                    );
                    dispatch(GlobalStateActions.setToogleFetch());
                } catch (error) {
                    deactivationSuccess = false;
                }
            }

            const treeInput = {
                companyId: company?.id,
                productId: product?.productId,
                taxYearId: product?.taxYear,
                moduleId: getModuleId(product, company, municipalState),
            };

            const data: FixMeLater = await GQLService.fetchGraphQLData(
                returnTreeQuery,
                { treeInput },
                accessToken
            );

            dispatch(
                GlobalStateActions[product?.productName].setTree(data.tree)
            );

            // Reset the state after saving.
            setActivateReturnNodes(new Set());
            setDeactivateReturnNodes(new Set());

            if (activationSuccess && deactivationSuccess) {
                handleSnackbar("Returns updated successfully!", "success");
            } else {
                handleSnackbar(
                    "There has been an error updating the return statuses for some returns.",
                    "error"
                );
            }
        } catch (error) {
            console.error(error);
            handleSnackbar(
                "There has been an error updating the return statuses for some returns.",
                "error"
            );
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const listItems = folderNode?.returnNodes
        ?.filter((returnNode: ReturnNode) => {
            return filterForReturnNodes(returnNode, folderNode, company, true);
        })
        .filter((returnNode: ReturnNode) => {
            return !returnNode?.isActive; // Only displaying inactive returns in the dialog.
        })
        .map((returnNode) => (
            <ListItem key={returnNode.id + "-key"} disablePadding>
                <ListItemButton
                    disabled={isLoading} 
                    role={undefined}
                    onClick={() =>
                        handleActivateChange(returnNode.id.toString())
                    }
                    dense
                >
                    <ListItemIcon>
                        <Checkbox
                            disabled={isLoading}
                            edge="start"
                            checked={activatedReturnNodes.has(
                                returnNode.id.toString()
                            )}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText
                        id={returnNode.displayName}
                        primary={`${returnNode.displayName}`}
                    />
                </ListItemButton>
            </ListItem>
        ));

    const handleConfirmationDialogClose = () => {
        setShowDeactivateConfirmation(false);
    };

    const handleConfirmationDialogConfirm = async () => {
        setShowDeactivateConfirmation(false);
        await saveData();
    };

    return (
        <>
            <Modal
                title={`Activate Returns - ${folderNode?.attributes?.displayName}`}
                open={open}
                onClose={handleCancel}
                actions={[
                    {
                        label: "Activate",
                        onClick: handleSave,
                        isLoading: isLoading,
                    },
                ]}
            >
                <List>{listItems}</List>
            </Modal>

            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />

            <Dialog
                open={showDeactivateConfirmation}
                onClose={handleConfirmationDialogClose}
            >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to deactivate form(s)? This will
                        permanently delete all associated data.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledFlexContainer justify={"center"}>
                        <Button
                            onClick={handleConfirmationDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmationDialogConfirm}
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </StyledFlexContainer>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FolderNodeDialog;
